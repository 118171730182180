// Navigation

$(function () {
  const classNameOpen = 'open';
  const selectorDataToggle = $('[data-toggle="slide"]')

  selectorDataToggle.on('click', function (e) {
    e.stopPropagation();
    e.preventDefault();
    let target = $(this).data('target');
    $(target).toggleClass(classNameOpen);
  });

  $('#mainSearch, #mainSearchMobile').on('shown.bs.collapse', function () {
    $(this).find('input').focus()
  })
})
